<template>
    <section>
      
      <div class="columns is-marginless">
        <div class="column is-12">    
            <div class="columns is-gapless is-marginless is-centered is-mobile">
              <div class="column is-narrow is-mobile">
                <div class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >companies</div>
              </div>
            </div>
        </div>
      </div>
        
      
      <div class="is-mobile is-gapless is-centered is-multiline m2rem">
        <b-table
            :data="companyData"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableIsLoading"
            :mobile-cards="true">

            <b-table-column field="companies_id" label="ID" width="80" :td-attrs="columnTdAttrs" numeric v-slot="props" centered searchable>
                <a :href='`${WEB_ROOT}/company/${props.row.companies_id}`' target='_blank'>{{ props.row.companies_id }}</a>
            </b-table-column>

            <b-table-column field="companies_name" label="name" width="100" :td-attrs="columnTdAttrs" v-slot="props" searchable>
              <div class="highlight">
                <a :href='`${WEB_ROOT}/company/${props.row.companies_id}`' target='_blank'>{{ props.row.companies_name }}</a>
                <br />
                <a :href='`${SITE_ROOT}/companies/${props.row.companies_id}/${props.row.companies_name.split(` `).join(`-`) }`' target='_blank'>WA</a>
              </div>
            </b-table-column>
            <b-table-column field="companies_prefix" label="prefix" width="100" :td-attrs="columnTdAttrs" v-slot="props" searchable>
              <div class="highlight">
                <a :href='`${WEB_ROOT}/company/${props.row.companies_id}`' target='_blank'>{{ props.row.companies_prefix }}</a>
              </div>
              {{ props.row.post_city }}
              
            </b-table-column>
            <b-table-column field="name" label="departments" width="100" :td-attrs="columnTdAttrs" v-slot="props" searchable>
              <div v-for="(department) in props.row.departments" v-bind:key="`department_${department.id}_index`" class="smallLetters"
              :class="department.is_customer === 1 ? 'isAvtive' : 'notActive'">
                {{ department.id }} - {{ department.name }} {{ department.prefix ? department.prefix : ''  }} - {{ department.post_city }} 
                {{ department.is_customer === 1 ? '' : ' - [NOT customer!!]' }}
                {{ department.is_active === 1 ? '' : ' - [NOT active!!]' }}
              </div>
            </b-table-column>
         
            
            <b-table-column field="is_active" label="id phase max cancel" width="300" :td-attrs="columnTdAttrs" v-slot="props" searchable>
                <div v-for="(schedule) in props.row.schedules" v-bind:key="`${schedule.id}_index`" :class="schedule.is_active === 1 ? 'isAvtive' : 'notActive'" >
                  {{ schedule.id }} - {{ schedule.wa_phases_name }} - {{ schedule.wa_phases_max_shifts_cancelation }}% 
                  {{ schedule.is_active === 1 ? '' : ' - [not active]' }}
                </div>
            </b-table-column>

            <b-table-column field="companies_is_active" label="active" width="80" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.companies_is_active }}
            </b-table-column>

            <b-table-column field="companies_is_customer" label="customer" width="80" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.companies_is_customer }}
            </b-table-column>
            
            <!-- <b-table-column field="companies_blocked_days" width="40" label="blocked Days" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.companies_blocked_days }}
            </b-table-column>
            <b-table-column field="companies_blocked_days_max_consecutive" label="blocked Days Consecutive" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.companies_blocked_days_max_consecutive }}
            </b-table-column> -->
            
            <b-table-column field="company_user_count" label="users" width="80" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered >
              <div class="highlight">
                <a :href='`${WEB_ROOT}/company/${props.row.companies_id}/users`' target='_blank'>{{ props.row.company_user_count }}</a>
              </div>
            </b-table-column>
            
            
            <b-table-column label="actions" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered >
              <div class="menuUrls">
                <div class="email">
                  <a :href='`${WEB_ROOT}/company/${props.row.companies_id}/emailTemplate`' target='_blank'>emailTemplate</a>
                </div>
                
                <div class="bigchecker">
                  <a :href='`${WEB_ROOT}/company/${props.row.companies_id}/bigchecker`' target='_blank'>bigchecker</a>
                </div>
                
                <div class="intershift Import">
                  <a :href='`${WEB_ROOT}/company/${props.row.companies_id}/intershiftImport`' target='_blank'>intershift Import</a>
                </div>
                
                <div class="importshifts">
                  <a :href='`${WEB_ROOT}/company/${props.row.companies_id}/importShifts`' target='_blank'>import Shifts</a>
                </div>
                
                <div class="importshifts">
                  <a :href='`${WEB_ROOT}/company/${props.row.companies_id}/schedulePermissions`' target='_blank'>permissions</a>
                </div>
                
                <div class="importshifts">
                  <a :href='`${WEB_ROOT}/company/${props.row.companies_id}/maximus`' target='_blank'>maximus</a>
                </div>
              </div>
              
            </b-table-column>
            
            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
      </div>
      
      
      <b-notification :closable="false">
        <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
      </b-notification>
    </section>
</template>

<script>

import axios_api from '@/plugins/axios_api';
// import { waitTime }         from '@/helpers/functions';
// import { mapState } from 'vuex';

/* eslint-disable */
import { WEB_ROOT, API_ROOT } from '@/config/app.js';

    export default {
      data() {
        return {
          companyData         : [],
          tableIsLoading      : false,
          WEB_ROOT: WEB_ROOT,
          API_ROOT: API_ROOT,
          SITE_ROOT     : process.env.VUE_APP_SITE_MAIN,
          
          pageLoading         : false,
          pageLoadingTimeout  : null,
        }
      },
      async mounted() {
        this.ShowPageLoading(15000)
        this.getCompanyData()
      },
      methods: {
        async getCompanyData() {
          let response = await axios_api.get(`${API_ROOT}/schedule/companies`, {});
          if (response.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get company data', type: 'is-danger' })
            this.HidePageLoading()
            return;
          }
          // console.info(`response:`, response)
          this.companyData = response.data.payload
          this.HidePageLoading()
        },
        
        /* eslint-disable-next-line */        
        dateThAttrs(column) {
          return null
        },
                
        /* eslint-disable-next-line */
        columnTdAttrs(row, column) {
          return null
        },
        
        async ShowPageLoading(maxDuration = 30000){
          this.pageLoading = true;
          
          if (this.pageLoadingTimeout !== null) {
            clearTimeout(this.pageLoadingTimeout);
          }
          
          this.pageLoadingTimeout = setTimeout(() => {
            this.HidePageLoading();
          }, maxDuration);
        },
        
        async HidePageLoading() {
          this.pageLoading = false;
          if (this.pageLoadingTimeout !== null) {
            clearTimeout(this.pageLoadingTimeout);
          }
        },
      }
    }
</script>

<style scoped>
  .highlight{
    color: #0000cc;
    text-decoration: underline;
  }
  
  /* .isAvtive{
    color: green
  } */
  .notActive{
    color: red
  }
  
  .smallLetters{
    font-size: 12px;
    margin-bottom: 15px;;
  }
  
  .menuUrls a:hover{
    text-decoration: underline;
  }
  
</style>